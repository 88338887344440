import { action, decorate } from "mobx";
import UserApi from "../api/UserApi";
import authStore from './authStore';

class UserStore
{
    user = undefined;

    pullUser()
    {
        return UserApi.getMe({
            token: authStore.token
        }).then((user) => {
            this.setUser(user);
        });
    }

    forgetUser() {
        this.user = undefined;
    }

    setUser(user) {
        this.user = user;
    }
}

decorate(UserStore, {
    setUser: action,
    pullUser: action,
    forgetUser: action
});


export default new UserStore();