import React from 'react'
import LinearProgress from '@material-ui/core/LinearProgress';

import AppBar from '../navigation/AppBar';

class PageContainer extends React.Component
{
    render()
    {
        const { title, loading, onMenuClick, rightComponent, withBackButton, goBack } = this.props;

        return (
        <div className="pageContainer">
            <AppBar
                title={title}
                onMenuClick={onMenuClick}
                rightComponent={rightComponent}
                withBackButton={withBackButton}
                goBack={goBack}
            />

            {loading && (
                <LinearProgress />
            )}

            <div>
                {this.props.children}
            </div>
        </div>
        )
    }
}

export default PageContainer;