import ApiCall from '../lib/ApiCall'
import Globals from '../lib/Globals';

const UserApi = {
    async getAll(options)
    {
        options = options || {};
        return ApiCall.get(Globals.API.URLS.USERS+'/', options);
    },

    async getMe(options)
    {
        options = options || {};
        return ApiCall.get('me', options);
    },

    async get(id, options)
    {
        options = options || {};
        return ApiCall.get(Globals.API.URLS.USERS+'/'+id, options);
    },

    async remove (id, options)
    {
        options = options || {};
        return ApiCall.delete(Globals.API.URLS.USERS+'/'+id, options);
    },
    
    async update (id, data, options)
    {
        options = options || {};
        options.data = data;
        return ApiCall.put(Globals.API.URLS.USERS+'/'+id, options);
    },

    async store (data, options)
    {
        options = options || {};
        options.data = data;
        return ApiCall.post(Globals.API.URLS.USERS+'/', options);
    },
};

export default UserApi
