import React from 'react';
import {Link} from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';

import PageContainer from "../components/helpers/PageContainer";

import MatchApi from '../api/MatchApi'

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: 'inline',
    },
});

class Matches extends React.Component
{
    constructor ()
    {
        super();

        this.state = {
            loading: true,
            matches: [],
        };
    }

    async componentDidMount ()
    {
        await this.reloadList();
    }

    async reloadList()
    {
        const matches = await MatchApi.getAll();

        this.setState({
            loading: false,
            matches: matches._embedded.items
        })
    }

    render () {
        const { classes } = this.props;
        const { loading, matches } = this.state;

        const MatchesList = matches.map((match, index) => {
            return (
                <ListItem alignItems="center" key={index} button component={Link} to={"/match/"+match.id}>
                    <ListItemIcon>
                        <i className={'flaticon-soccer-field-1'} />
                    </ListItemIcon>
                    <ListItemText
                        primary={match.name}
                        secondary={
                            <React.Fragment>
                                <Typography component="span" className={classes.inline} color="textPrimary">
                                    Cancha de {match.players_limit}
                                </Typography>
                                {" - Jugadores confirmados: "+match.match_players.length}
                            </React.Fragment>
                        }
                    />
                </ListItem>
            )
        });

        return (
            <PageContainer
                title={'Hay Partido!'}
                loading={loading}
                { ... this.props }
            >
                <h3 style={{padding: '16px', marginBottom: '0'}}>Partidos disponibles:</h3>
                <List className={classes.root}>
                    {MatchesList}
                </List>
            </PageContainer>
        )
    }
}

export default withStyles(styles)(Matches);
