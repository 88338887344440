import fetch from 'isomorphic-fetch'

import Globals from './Globals';

const ApiCall = {
    checkStatus(response)
    {
        if (response.status >= 200 && response.status < 300)
        {
            return response;
        } else {
            throw response.json();
        }
    },

    parseJSON(response)
    {
        return response.json();
    },

	async fetchAsync(fetchUrl, options)
    {
        let jsonData = options.data;
        let method = options.method || 'GET';
        let url = Globals.API.BASE_URL + fetchUrl;

        let request = {
            method: method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                ...(options.headers || {}),
            },
        };

        if(options.token)
        {
            request.headers.Authorization = 'Bearer ' + options.token;
        }

        if(options.role)
        {
            request.headers.role_id = options.role;
        }

        if(jsonData)
        {
            if (method === 'GET')
            {
                url += (url.indexOf('?') >= 0 ? '&' : '?') + Object.keys(jsonData).map(function(key){
                        return key+'='+JSON.stringify(jsonData[key]);
                    }).join('&');
            } else {
                request.body = JSON.stringify(jsonData);
            }
        }

        return fetch(url, request)
        .then(this.checkStatus)
        .then(this.parseJSON)
        .then(function(data)
        {
            if(options.callback) {
                options.callback(data);
            }
            return data;
        }).catch(function(responseError)
        {
            console.log('request failed', responseError);
            if(options.callback) {
                options.callback(responseError);
            }

            throw responseError;
        })
    },

    async post(fetchUrl, options)
    {
        options = options || {};
        options.method = options.method || 'POST';
        return this.fetchAsync(fetchUrl, options);
    },

    async get(fetchUrl, options)
    {
        options = options || {};
        options.method = options.method || 'GET';
        return this.fetchAsync(fetchUrl, options);
    },

    async delete(fetchUrl, options)
    {
        options = options || {};
        options.method = options.method || 'DELETE';
        return this.fetchAsync(fetchUrl, options);
    },

    async put(fetchUrl, options)
    {
        options = options || {};
        options.method = options.method || 'PUT';
        return this.fetchAsync(fetchUrl, options);
    },
};

export default ApiCall

/* Example to use this function:
  var url = 'dummy_url';
  var data = {dummy: 'dummy data',};
  var token = this.props.params.token || localStorage.getItem(JWToken);//optional
  ApiCall.get(url, {data, callback:this.callback, token:token});
*/
