import React from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import PageContainer from "../components/helpers/PageContainer";

import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
    },
    loginButton: {
        marginTop: theme.spacing.unit,
        color: theme.palette.secondary.main,
    }
});


class Register extends React.Component
{
    constructor ()
    {
        super();

        this.state = {
            loading: false,
            user: {
                name: "",
                email: "",
                password: "",
            }
        };

        this.registerHandler = this.registerHandler.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange (e)
    {
        let user = this.state.user;
        user[e.target.id] = e.target.value;

        this.setState({
            user: user
        });
    }

    async registerHandler (e)
    {
        e.preventDefault();

        await this.props.authStore.register(this.state.user.name, this.state.user.email, this.state.user.password);
        this.props.changeUrl('/');
    }

    render () {
        const {classes, changeUrl} = this.props;

        return (
            <PageContainer
                title={'Registración'}
                loading={this.state.loading}
                {...this.props}
            >
                <main className={classes.main}>
                    <CssBaseline/>
                    <Paper className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <PersonAddIcon/>
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Registración
                        </Typography>
                        <form className={classes.form}
                              onSubmit={this.registerHandler}
                        >
                            <FormControl margin="normal" required fullWidth>
                                <InputLabel htmlFor="name">Nombre</InputLabel>
                                <Input id="name" name="name" autoComplete="name" autoFocus
                                   value={this.state.user.name} onChange={this.handleChange}
                                />
                            </FormControl>
                            <FormControl margin="normal" required fullWidth>
                                <InputLabel htmlFor="email">Dirección de email</InputLabel>
                                <Input id="email" name="email" type="email" autoComplete="email"
                                   value={this.state.user.email} onChange={this.handleChange}
                                />
                            </FormControl>
                            <FormControl margin="normal" required fullWidth>
                                <InputLabel htmlFor="password">Contraseña</InputLabel>
                                <Input name="password" type="password" id="password" autoComplete="current-password"
                                   value={this.state.user.password} onChange={this.handleChange}
                                />
                            </FormControl>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                            >
                                Registrarme
                            </Button>
                        </form>
                        <Button className={classes.loginButton} onClick={() => changeUrl('/login')}>
                            Ya tengo cuenta, quiero ingresar.
                        </Button>
                    </Paper>
                </main>
            </PageContainer>
        );
    }
}

Register.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default inject("userStore", "authStore")(observer(withStyles(styles)(Register)));