const Globals = {
    API: {
        BASE_URL: process.env.REACT_APP_API_BASE_URL,
        URLS: {
            LOGIN: 'login',
            MATCHES: 'matches',
            PLAYERS: 'players',
            USERS: 'users',
        },
        REGEX: {
            PASS: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
            NAME: /^[a-zA-ZÀ-ú ]+$/,
            PHONE: /^\s*(?:\+?(\d{1}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
            EMAIL: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        },
        SERVER_ERROR: {},
        VALIDATION_MESSAGES: {
        },
        ICONS_MAP: {},
    }
};

export default Globals;
