import React from 'react';
import { Router, Route, Switch } from 'react-router-dom'
import {
    CSSTransition,
    TransitionGroup,
} from 'react-transition-group';

import Matches from '../pages/Matches'
import Match from '../pages/Match'
import Login from '../pages/Login'
import Register from '../pages/Register'

class Content extends React.Component
{
    shouldComponentUpdate()
    {
        return false;
    }

    render ()
    {
        /*let appWidth = '232px';
        if (!this.props.isOpen)
        {
            appWidth = '5px';
        } else {
            appWidth = '232px';
        }*/

        return (
            <div className="wrapper">
                <Router history={this.props.history}>
                    <Route render={({ location }) => (
                        <TransitionGroup>
                            <CSSTransition
                                key={location.key}
                                classNames="fade"
                                timeout={300}
                            >
                                <Switch location={location}>
                                    <Route path='/' exact component={(props) => (<Matches {...this.props} {...props} />)} />
                                    <Route path='/match/:id' exact component={(props) => (<Match {...this.props} {...props} />)} />
                                    <Route path='/login' exact component={(props) => (<Login {...this.props} {...props} />)} />
                                    <Route path='/register' exact component={(props) => (<Register {...this.props} {...props} />)} />
                                </Switch>
                            </CSSTransition>
                        </TransitionGroup>
                    )}
                    />
                </Router>
            </div>
        );
    }
}

export default Content
