import React from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import PersonIcon from '@material-ui/icons/Person';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import PageContainer from "../components/helpers/PageContainer";

import withStyles from '@material-ui/core/styles/withStyles';
import userStore from "../stores/userStore";

const styles = theme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing.unit * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
    },
    registerButton: {
        marginTop: theme.spacing.unit,
        color: theme.palette.secondary.main,
    },
    error: {
        margin: theme.spacing.unit,
        color: '#8c0000'
    }
});

class Login extends React.Component
{
    constructor ()
    {
        super();

        this.state = {
            loading: false,
            error: false,
            user: {
                email: "",
                password: "",
            }
        };

        this.loginHandler = this.loginHandler.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange (e)
    {
        let user = this.state.user;
        user[e.target.id] = e.target.value;

        this.setState({
            user: user
        });
    }

    loginHandler (e)
    {
        e.preventDefault();

        this.setState({
            loading: true
        });

        this.props.authStore
            .login(this.state.user.email, this.state.user.password)
            .then(() => {
                this.props.changeUrl('/');
            })
            .catch(() => {
                this.setState({
                    error: true,
                    loading: false
                });
            })
        ;
    }

    render () {
        const {classes, changeUrl} = this.props;

        return (
            <PageContainer
                title={'Iniciar sesión'}
                loading={this.state.loading}
                {...this.props}
            >
                <main className={classes.main}>
                    <CssBaseline/>
                    <Paper className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <PersonIcon/>
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Ingresar
                        </Typography>
                        {this.state.error?(
                            <Typography component="h4" className={classes.error}>
                                Revisá tus datos y volvé a intentarlo
                            </Typography>
                        ):''}
                        <form className={classes.form}
                              onSubmit={this.loginHandler}
                        >
                            <FormControl margin="normal" required fullWidth>
                                <InputLabel htmlFor="email">Dirección de email</InputLabel>
                                <Input id="email" name="email" autoComplete="email" autoFocus
                                       value={this.state.user.email} onChange={this.handleChange}
                                />
                            </FormControl>
                            <FormControl margin="normal" required fullWidth>
                                <InputLabel htmlFor="password">Contraseña</InputLabel>
                                <Input name="password" type="password" id="password" autoComplete="current-password"
                                       value={this.state.user.password} onChange={this.handleChange}
                                />
                            </FormControl>
                            <FormControlLabel
                                control={<Checkbox value="remember" color="primary"/>}
                                label="Mantenerme conectado"
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                            >
                                Ingresar
                            </Button>
                        </form>
                        <Button className={classes.registerButton} onClick={() => changeUrl('/register')}>
                            No tengo cuenta. Registrarme
                        </Button>
                    </Paper>
                </main>
            </PageContainer>
        );
    }
}

Login.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default inject("authStore")(observer(withStyles(styles)(Login)));