export default (theme) => ({
    closeSnackbar: {
        width: theme.spacing.unit * 4,
        height: theme.spacing.unit * 4,
    },
    loadingContainer: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    }
})
