import { observable, action, decorate, reaction } from "mobx";
import UserApi from '../api/UserApi'
import AuthApi from "../api/AuthApi";
import userStore from './userStore';

class AuthStore
{
    token = window.localStorage.getItem('jwt');

    constructor()
    {
        reaction(
            () => this.token,
            token => {
                if (token) {
                    window.localStorage.setItem('jwt', token);
                } else {
                    window.localStorage.removeItem('jwt');
                }
            }
        );
    }

    login (email, password)
    {
        return AuthApi.login({
            _username: email,
            _password: password,
        }).then((response) => {
            this.token = response.token;

            return userStore.pullUser();
        });
    }

    async register (firstName, email, password)
    {
        await UserApi.store({
            email: email,
            plainPassword: password,
            player: {
                firstName: firstName
            }
        });

        this.login(email, password);
    }

    async logout ()
    {
        userStore.forgetUser();
        this.token = undefined;
    }
}

decorate(AuthStore, {
    token: observable,
    login: action,
    logout: action,
    register: action
});


export default new AuthStore();