const AppDrawerStyles = (theme) => ({
    list: {
        width: 250,
    },
    userInfo: {
        paddingLeft: theme.spacing.unit * 3,
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    }
});

export default AppDrawerStyles
