import ApiCall from '../lib/ApiCall'

const AuthApi = {
    async login(data, options)
    {
        options = options || {};
        options.data = data;
        return ApiCall.post('login_check', options);
    },

    async logout(options)
    {
        options = options || {};
        return ApiCall.get('logout', options);
    },
};

export default AuthApi
