import React from 'react'
import { inject, observer } from 'mobx-react';
import { withStyles, createStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Typography from '@material-ui/core/Typography'
import ListIcon from '@material-ui/icons/List'
import PersonIcon from '@material-ui/icons/Person'
import CloseIcon from '@material-ui/icons/Close'
import PersonAddIcon from '@material-ui/icons/PersonAdd'

import AppDrawerStyles from '../../styles/AppDrawerStyles'

const styles = createStyles(AppDrawerStyles);

class Menu extends React.Component
{
    constructor (props)
    {
        super(props);

        this.state = {
            isOpen: this.props.isOpen
        };

        this.onLogoutHandler = this.onLogoutHandler.bind(this);
    }

    componentWillReceiveProps(nextProps)
    {
        this.setState({
              isOpen: nextProps.isOpen
        });
    }

    clickHandler(item)
    {
        if (item.url !== undefined) {
            this.props.changeUrl(item.url);
        } else {
            item.onClickHandler();
        }
    }

    onLogoutHandler()
    {
        this.props.authStore.logout();
        this.props.changeUrl('/');
    }

    render ()
    {
        const { userStore, classes, drawerOnClick, role } = this.props;

        const menuItems = [{
            title: 'Partidos',
            url: '/',
            iconComponent: <ListIcon />
            //roles: ['USER']
        }, {
            title: 'Ingresar',
            url: '/login',
            iconComponent: <PersonIcon />,
            onAuth: false,
            //roles: ['USER']
        }, {
            title: 'Registro',
            url: '/register',
            iconComponent: <PersonAddIcon />,
            onAuth: false,
            //roles: ['USER']
        }, {
            title: 'Salir',
            onClickHandler: this.onLogoutHandler,
            iconComponent: <CloseIcon />,
            onAuth: true,
            //roles: ['USER']
        },];

        return (
            <Drawer
                open={this.state.isOpen}
                onClick={drawerOnClick}
            >
                {userStore.user !== undefined?(
                    <Typography className={classes.userInfo}>
                        Bienvenido {userStore.user.player.first_name}
                    </Typography>
                ):('')}
                <div className={classes.list}>
                    <List>
                        {menuItems.map((item, index) =>
                        {
                            let hasRole = item.roles === undefined || item.roles.indexOf(role) !== -1;
                            let needRender = hasRole && (item.onAuth === undefined || item.onAuth === (userStore.user !== undefined));

                            let icon = (item.iconClass !== undefined)?(
                                <i className={'flaticon-football-1'} />
                            ):item.iconComponent;

                            return !needRender?'':(
                            <ListItem button key={index} onClick={() => {this.clickHandler(item)}}>
                                <ListItemIcon>
                                    {icon}
                                </ListItemIcon>
                                <ListItemText primary={item.title} />
                            </ListItem>
                            )
                        })}
                    </List>
                </div>
            </Drawer>
        )
    }
}

export default inject("userStore", "authStore")(observer(withStyles(styles)(Menu)));
