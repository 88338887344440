import React, { Component } from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Provider } from 'mobx-react';
import { green, indigo } from '@material-ui/core/colors'
import 'typeface-roboto';

import './App.css';
import Layout from './components/Layout.jsx'
import userStore from "./stores/userStore";
import authStore from "./stores/authStore";

const stores = {
    userStore,
    authStore
};

const theme = createMuiTheme({
    palette: {
        secondary: {
            main: indigo[900]
        },
        primary: {
            main: green[700]
        }
    },
    typography: {
        useNextVariants: true,
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '"Lato"',
            'sans-serif'
        ].join(',')
    }
});

class App extends Component {
    componentDidMount() {
        if ("Notification" in window) {
            Notification.requestPermission((result) => {
                if (result !== 'denied') {
                    if (navigator.serviceWorker) {
                        navigator.serviceWorker.register('sw.js');
                    }
                }
            });
        }
    }

    render() {
        return (
            <MuiThemeProvider theme={theme}>
                <Provider {...stores}>
                    <Layout/>
                </Provider>
            </MuiThemeProvider>
        )
    }
}

export default App;
