import React from 'react'
import { inject, observer } from 'mobx-react';
import createHistory from 'history/createHashHistory'
import { withStyles, createStyles } from '@material-ui/core/styles'
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';

import Menu from './navigation/Menu.jsx'
import Content from './Content.jsx'

import LayoutStyles from '../styles/LayoutStyles'

const styleSheet = createStyles(LayoutStyles);

const history = createHistory();

let routeParams = {};

class Layout extends React.Component
{
    constructor (props)
    {
        super(props);

        this.state = {
            menuState: false,
            snackbarOpen: false,
            snackbarMessage: 'Done successfully',
            currentPath: history.location.pathname,
            loading: true
        };

        this.showSnackbar = this.showSnackbar.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.logoutClickHandler = this.logoutClickHandler.bind(this);
        this.handleSnackbarClose = this.handleSnackbarClose.bind(this);
        this.changeUrl = this.changeUrl.bind(this);
        this.goBack = this.goBack.bind(this);
        this.getRouteParams = this.getRouteParams.bind(this);

        history.listen((location) => {
            this.setState({
                currentPath: location.pathname
            });
        });
    }

    componentDidMount()
    {

        document.execCommand('copy');
        if (this.props.authStore.token) {
            this.props.userStore.pullUser()
                .finally(() => {
                    this.setState({
                        loading: false
                    });
                })
            ;
        } else {
            this.setState({
                loading: false
            });
        }
    }

    showSnackbar(options)
    {
        this.setState({ snackbarOpen: true, ...options });
    }

    toggleMenu ()
    {
        const toggled = !this.state.menuState;

        this.setState({
            menuState: toggled
        })
    }

    handleSnackbarClose(reason)
    {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({ snackbarOpen: false });
    }

    logoutClickHandler()
    {
        //this.props.dispatch(logoutUser())
    }

    changeUrl(url, params)
    {
        routeParams = {};
        if(params !== undefined)
        {
            routeParams = params;
        }

        history.push(url);
    }

    goBack()
    {
        history.goBack();
    }

    getRouteParams()
    {
        return routeParams;
    }

    render ()
    {
        const classes = this.props.classes;
        const { userStore, role } = this.props;
        const isAuthenticated = userStore.user !== undefined;

        return (
            this.state.loading === false?(
                <div className={classes.appFrame}>
                    <Menu
                        isOpen={this.state.menuState}
                        drawerOnClick={this.toggleMenu}
                        changeUrl={this.changeUrl}
                        currentPath={this.state.currentPath}
                        isAuthenticated={isAuthenticated}
                        role={role}
                    />

                    <div className="mainContent">
                        <Content
                            showSnackbar={this.showSnackbar}
                            history={history}
                            changeUrl={this.changeUrl}
                            goBack={this.goBack}
                            getRouteParams={this.getRouteParams}
                            onMenuClick={this.toggleMenu}
                            onLogoutClick={this.logoutClickHandler}
                            role={role}
                        />
                    </div>

                    <Snackbar
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        open={this.state.snackbarOpen}
                        autoHideDuration={6e3}
                        onRequestClose={this.handleSnackbarClose}
                        message={this.state.snackbarMessage}
                        action={[
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                className={classes.closeSnackbar}
                                onClick={this.handleSnackbarClose}
                            >
                                <CloseIcon />
                            </IconButton>,
                        ]}
                    />
                </div>
            ):(
                <div className={classes.loadingContainer}>
                    <CircularProgress />
                </div>
            )
        )
    }
}


/*Layout.propTypes = {
    dispatch: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    role: PropTypes.string,
    errorMessage: PropTypes.string,
};*/

// These props come from the application's
// state when it is started
/*function mapStateToProps(state)
{
    const { auth } = state;
    const { isAuthenticated, role, errorMessage } = auth;

    return {
        isAuthenticated,
        errorMessage,
        role
    }
}*/

//export default connect(mapStateToProps)(withStyles(styleSheet)(Layout));
export default inject("userStore", "authStore")(observer(withStyles(styleSheet)(Layout)));
