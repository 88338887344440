import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton'
import ListItem from '@material-ui/core/ListItem';
import {
    Button, Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Fab, Slide,
} from "@material-ui/core";
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import PersonIcon from '@material-ui/icons/Person'
import ShareIcon from '@material-ui/icons/Share'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import GroupAddIcon from '@material-ui/icons/GroupAdd'

import PageContainer from "../components/helpers/PageContainer";

import MatchApi from '../api/MatchApi'
import '../styles/Match.css';
import TextField from "@material-ui/core/es/TextField/TextField";
import authStore from "../stores/authStore";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import Tooltip from '@material-ui/core/Tooltip';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: 'inline',
    },
    fabActions: {
        position: 'absolute',
        bottom: theme.spacing.unit * 3,
        right: theme.spacing.unit * 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    dialogContentText: {
        marginBottom: theme.spacing.unit * 3
    },
    close: {
        padding: theme.spacing.unit / 2,
    },
});

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class Match extends React.Component
{
    constructor ()
    {
        super();

        this.state = {
            loading: true,
            fabOpened: true,
            openSharedSnackbar: false,
            openAddGuestDialog: false,
            match: [],
            guestPlayer: {
                firstName: '',
                email: ''
            }
        };

        this.shareMatch = this.shareMatch.bind(this);
        this.handleAddOwnPlayer = this.handleAddOwnPlayer.bind(this);
        this.handleAddGuestPlayer = this.handleAddGuestPlayer.bind(this);
        this.handleCloseAddGuestPlayerDialog = this.handleCloseAddGuestPlayerDialog.bind(this);
        this.handleGuestChangeField = this.handleGuestChangeField.bind(this);
    }

    componentDidMount ()
    {
        this.reloadData();
    }

    reloadData()
    {
        this.setState({
            loading: true,
        });

        MatchApi.get(this.props.match.params.id)
            .then(match => {
                this.setState({
                    loading: false,
                    match: match,
                })
            })
        ;
    }

    shareMatch() {
        let playerNames = '';
        this.state.match.match_players.forEach(function (matchPlayer, index) {
            console.log(matchPlayer.player.first_name)
            playerNames += (index+1)+'. '+matchPlayer.player.first_name+'\n';
        });
        console.log(this.state.match.match_players);
        let shareUrl = "https://haypartido.odiseo.io/#/match/" + this.state.match.id;
        let shareText = "Te invito a jugar un partido de futbol ⚽. Estos son los jugadores actuales:\n\n" + playerNames+"\n";
        if (navigator.share) {
            navigator.share({
                title: "Unirse a un partido",
                text: shareText,
                url: shareUrl
            }).then(() => {
                console.log("Data was shared successfully");
            }).catch((err) => {
                console.error("Share failed:", err.message);
            });
        } else {
            const url = 'https://api.whatsapp.com/send?text=' + encodeURIComponent(shareText) + 'Entrá acá: ' + shareUrl;
            window.open(url, '_blank')
            /* let textField = document.createElement('textarea');
            textField.innerText = "Te invito a jugar un partido de futbol. Sumate! " +shareUrl;
            document.body.appendChild(textField);
            textField.select();
            document.execCommand('copy');
            textField.remove();
            */
            this.setState({ openSharedSnackbar: true });
        }
    }

    handleAddOwnPlayer = () => {
        if (this.props.userStore.user === undefined) {
            this.props.changeUrl('/login');
        }

        this.setState({
            loading: true,
        });

        MatchApi.addOwnPlayer(this.state.match.id, {
            token: authStore.token
        }).then(() => {
            this.reloadData();
        });
    };

    handleAddGuestPlayer = () => {
        if (this.props.userStore.user === undefined) {
            this.props.changeUrl('/login');
        }

        this.setState({
            loading: true,
        });

        MatchApi.addGuestPlayer(this.state.match.id, {
            firstName: this.state.guestPlayer.firstName,
            email: this.state.guestPlayer.email
        }, {
            token: authStore.token
        }).then(() => {
            this.setState({
                openAddGuestDialog: false,
            });

            this.reloadData();
        });
    };

    handleOpenAddGuestPlayerDialog = () => {
        if (this.props.userStore.user === undefined) {
            this.props.changeUrl('/login');
        }

        this.setState({ openAddGuestDialog: true });
    };

    handleCloseAddGuestPlayerDialog = () => {
        if (this.props.userStore.user === undefined) {
            this.props.changeUrl('/login');
        }

        this.setState({ openAddGuestDialog: false });
    };

    handleGuestChangeField = (e) => {
        let guestPlayer = this.state.guestPlayer;
        guestPlayer[e.target.id] = e.target.value;

        this.setState({
            guestPlayer: guestPlayer
        });
    };

    handleCloseSharedSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({ openSharedSnackbar: false });
    };

    render () {
        const { classes } = this.props;
        const { loading, match, fabOpened } = this.state;

        const PlayersList = match.match_players?match.match_players.map((matchPlayer, index) => {
            return (
                <ListItem alignItems="flex-start" key={index}>
                    <ListItemIcon>
                        <PersonIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={(index+1)+". "+matchPlayer.player.first_name}
                    />
                </ListItem>
            )
        }):[];

        const appBarRightComponent = (

            <Tooltip title="Copiar al portapapeles" aria-label="Add">
                <IconButton onClick={this.shareMatch} color="inherit">
                    <ShareIcon/>
                </IconButton>
            </Tooltip>
        );

        return (
            <PageContainer
                title={match.name}
                loading={loading}
                rightComponent={appBarRightComponent}
                withBackButton
                { ... this.props }
            >
                <List className={classes.root}>
                    {PlayersList}
                </List>

                <div className={fabOpened?classes.fabActions+' fabOpened':classes.fabActions}>
                    <div className={'fab fabSecondary'}>
                        <Chip
                            label="Agregar invitado"
                            className="tooltip"
                        />
                        <Fab color="secondary" size="medium" aria-label="Agregar invitado" onClick={this.handleOpenAddGuestPlayerDialog}>
                            <GroupAddIcon />
                        </Fab>
                    </div>
                    <div className={'fab'}>
                        <Chip
                            label="Agregarte a tí"
                            className="tooltip"
                        />
                        <Fab color="primary" aria-label="Agregarte a tí" className={'fab'} onClick={this.handleAddOwnPlayer}>
                            <PersonAddIcon />
                        </Fab>
                    </div>
                </div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={this.state.openSharedSnackbar}
                    autoHideDuration={6000}
                    onClose={this.handleCloseSharedSnackbar}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">Copiado a portapapeles!</span>}
                    action={[
                        <IconButton
                            key="close"
                            aria-label="Cerrar"
                            color="inherit"
                            className={classes.close}
                            onClick={this.handleCloseSharedSnackbar}
                        >
                            <CloseIcon />
                        </IconButton>,
                    ]}
                />

                <Dialog
                    open={this.state.openAddGuestDialog}
                    onClose={this.handleCloseAddGuestPlayerDialog}
                    aria-labelledby="form-dialog-title"
                    className="addGuestDialog"
                    fullScreen
                    TransitionComponent={Transition}
                >
                    <DialogTitle id="form-dialog-title">Agregar invitado</DialogTitle>
                    <DialogContent>
                        <DialogContentText className={classes.dialogContentText}>
                            Completa el nombre e email del invitado que quieres agregar al partido.
                        </DialogContentText>
                        <TextField autoFocus id="firstName" type="text"
                            label="Nombre" fullWidth
                             value={this.state.guestPlayer.firstName} onChange={this.handleGuestChangeField}
                        />
                        <TextField id="email" type="email"
                               label="Dirección de email" fullWidth
                               value={this.state.guestPlayer.email} onChange={this.handleGuestChangeField}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseAddGuestPlayerDialog} color="default">
                            Cancelar
                        </Button>
                        <Button onClick={this.handleAddGuestPlayer} color="primary">
                            Agregar al partido
                        </Button>
                    </DialogActions>
                </Dialog>
            </PageContainer>
        )
    }
}

export default inject("userStore", "authStore")(observer(withStyles(styles)(Match)));
