import ApiCall from '../lib/ApiCall'
import Globals from '../lib/Globals';

const MatchApi = {
    async getAll(options)
    {
        options = options || {};
        return ApiCall.get(Globals.API.URLS.MATCHES+'/', options);
    },

    async get(id, options)
    {
        options = options || {};
        return ApiCall.get(Globals.API.URLS.MATCHES+'/'+id, options);
    },

    async remove (id, options)
    {
        options = options || {};
        return ApiCall.delete(Globals.API.URLS.MATCHES+'/'+id, options);
    },
    
    async update (id, data, options)
    {
        options = options || {};
        options.data = data;
        return ApiCall.put(Globals.API.URLS.MATCHES+'/'+id, options);
    },

    async store (data, options)
    {
        options = options || {};
        options.data = data;
        return ApiCall.post(Globals.API.URLS.MATCHES+'/', options);
    },

    async addOwnPlayer (id, options)
    {
        options = options || {};
        return ApiCall.post(Globals.API.URLS.MATCHES+'/'+id+'/add-player/me', options);
    },

    async addGuestPlayer (id, data, options)
    {
        options = options || {};
        options.data = data;
        return ApiCall.post(Globals.API.URLS.MATCHES+'/'+id+'/add-player', options);
    },
};

export default MatchApi
