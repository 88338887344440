import React from 'react'
import { withStyles, createStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import AppBarStyles from '../../styles/AppBarStyles'

const styles = createStyles(AppBarStyles);


class CustomAppBar extends React.Component {

    render()
    {
        const { classes, title, goBack, onMenuClick, rightComponent, withBackButton } = this.props;

        return (
            <div className={classes.root}>
                <AppBar position="static">
                    <Toolbar>
                        {withBackButton?(
                            <IconButton className={classes.menuButton} color="inherit" aria-label="Menu"
                                onClick={goBack}
                            >
                                <ArrowBackIcon/>
                            </IconButton>
                        ):(
                        <IconButton className={classes.menuButton} color="inherit" aria-label="Menu"
                            onClick={onMenuClick}
                        >
                            <MenuIcon/>
                        </IconButton>
                        )}
                        <Typography variant="h6" color="inherit" className={classes.grow}>
                            { title?title:"Hay Partido!" }
                        </Typography>

                        {rightComponent && (
                            <div>
                                {rightComponent}
                            </div>
                        )}
                    </Toolbar>
                </AppBar>
            </div>
        )
    }
}

export default withStyles(styles)(CustomAppBar)